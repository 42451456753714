import {TaskImportClientMapping, TaskImportObject} from 'shared/components/TasksImport/utils/types';

export function filterEmptyTasks(taskResponses: TaskImportObject[]) {
  if (!Array.isArray(taskResponses)) return [];
  return taskResponses.filter((taskResp) => !!taskResp.task);
}

export function getTasksSortFuncByMapping(mapping: TaskImportClientMapping) {
  if (mapping.outlineCode) {
    return (a, b) => a.task.outlineCode?.localeCompare(b.task.outlineCode, 'en', {numeric: true});
  } else if (mapping.uniqueId !== 'auto') {
    return (a, b) => a.task.uniqueId.localeCompare(b.task.uniqueId, 'en');
  } else {
    return (a, b) => {
      if (a.task.name) {
        return b.task.name ? a.task.name.localeCompare(b.task.name, 'en') : -1;
      } else if (b.task.name) {
        return 1;
      }
      return 0;
    };
  }
}

export function getFileExtension(fileName: string): string {
  const ext = fileName.split('.').pop();
  return ext === fileName ? '' : ext.toLowerCase();
}

export function isMppFile(fileName: string) {
  return getFileExtension(fileName) === 'mpp';
}
