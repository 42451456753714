import {GanttStatic} from 'dhtmlx-gantt';
import equal from 'fast-deep-equal';
import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  GANTT_COLUMNS_NAMES,
  GANTT_COLUMNS_SETTINGS,
  SELECTED_GANTT_COLUMNS,
} from 'modules/Tasks/components/Gantt/utils/constants';
import {GANTT_PREFERENCES_KEY} from 'shared/constants/common';
import {safeParseFromLocalStorage} from 'shared/helpers/ls';
import {getProjectCustomField} from 'shared/helpers/project';
import {usePrevious} from 'shared/hooks/core/usePrevious';
import {useLocalStorage} from 'shared/hooks/useLocalStorage';
import {useProfile} from 'shared/hooks/useProfile';
import {useProject} from 'shared/hooks/useProject';
import {ProjectCustomFieldDef} from 'shared/models/project';

import {useFilterContext} from '../../Filters/FilterProvider';
import {GanttTask} from '../types';
import {generateColumn} from '../utils/config';
import {
  isNonHidingColumn,
  getCustomColumnWidthByFieldType,
  getInlineEditorTypeForCustomColumn,
  getCustomColumnCellTemplate,
} from '../utils/functions';

import {DEFAULT_GRID_WIDTH, GanttLayoutSettings} from './useGanttLayoutSettings';

type GanttColumnConfig = {
  name: GANTT_COLUMNS_NAMES | string;
  defaultHide: boolean;
  hideByUser: boolean;
  touched: boolean;
  isCustom: boolean;
};

type GanttWithCustomColumnsNames = {
  name: GANTT_COLUMNS_NAMES | string;
  label?: string;
  isCustom?: boolean;
};

const columnOrder: GANTT_COLUMNS_NAMES[] = [
  GANTT_COLUMNS_NAMES.uniqueId,
  GANTT_COLUMNS_NAMES.icons,
  GANTT_COLUMNS_NAMES.duration,
  GANTT_COLUMNS_NAMES.predecessor,
  GANTT_COLUMNS_NAMES.startDate,
  GANTT_COLUMNS_NAMES.endDate,
  GANTT_COLUMNS_NAMES.responsible,
  GANTT_COLUMNS_NAMES.assignmentCount,
  GANTT_COLUMNS_NAMES.taskStatus,
  GANTT_COLUMNS_NAMES.subcontractor,
  GANTT_COLUMNS_NAMES.description,
  GANTT_COLUMNS_NAMES.responsible,
  GANTT_COLUMNS_NAMES.location,
  GANTT_COLUMNS_NAMES.type,
  GANTT_COLUMNS_NAMES.actualStart,
  GANTT_COLUMNS_NAMES.actualEnd,
  GANTT_COLUMNS_NAMES.inprogressDate,
  GANTT_COLUMNS_NAMES.doneDate,
  GANTT_COLUMNS_NAMES.comments,
  GANTT_COLUMNS_NAMES.averageLaborAbbr,
  GANTT_COLUMNS_NAMES.estimatedLaborAbbr,
  GANTT_COLUMNS_NAMES.progress,
  GANTT_COLUMNS_NAMES.estLaborHours,
  GANTT_COLUMNS_NAMES.phaseCode,
  GANTT_COLUMNS_NAMES.customCode,
  GANTT_COLUMNS_NAMES.csiCode,
];

const setColumnOrder = (name: GANTT_COLUMNS_NAMES) => {
  const colIndex = columnOrder.indexOf(name);
  // puts them in order shown above. If not found puts at the end.
  return colIndex === -1 ? Infinity : colIndex;
};

export function useGanttColumns(gantt: GanttStatic, projectId: string) {
  const [columns, setColumns] = useState<GanttWithCustomColumnsNames[]>([]);
  const {project} = useProject(projectId);
  const prevProject = usePrevious(project);
  const worker = useProfile();
  const {queryParams} = useFilterContext();

  const [getStoredSettings, storeColumnsConfig] = useLocalStorage<GanttColumnConfig[]>({
    key: GANTT_COLUMNS_SETTINGS,
    path: `${worker.id}.${projectId}.${gantt.name}`,
    defaultValue: null,
    enabled: !!gantt.name && !!projectId && !!worker.id,
  });
  const [getLayoutSettings] = useLocalStorage<GanttLayoutSettings>({
    key: GANTT_PREFERENCES_KEY,
    path: `${gantt.name}.layout`,
    defaultValue: {divider: DEFAULT_GRID_WIDTH, columns: [], columnOrder: null},
    enabled: !!gantt.name,
  });
  const [columnsConfig, setColumnsConfig] = useState<GanttColumnConfig[]>([]);

  function addNewCustomColumn(customColumn: ProjectCustomFieldDef) {
    setColumnsConfig((prev) => {
      const next = [
        {
          name: customColumn.internalFieldName,
          defaultHide: false,
          hideByUser: false,
          touched: false,
          isCustom: true,
        },
      ].concat(prev);
      storeColumnsConfig(next);
      return next;
    });
  }

  useEffect(() => {
    // render columns when some custom column field has changed (e.g. label)
    if (project?.id === prevProject?.id && !equal(project.customFieldDef, prevProject.customFieldDef)) {
      setColumns(populateDropdownItems());
      renderColumns();
    }
  }, [project, prevProject]);

  const updateColumnConfig = useCallback(
    (name: string, hideByUser: boolean) => {
      setColumnsConfig((prev) => {
        const next = [...prev];
        const column = next.find((col) => col.name === name);
        if (!column) {
          const ganttCol = gantt.config.columns.find((col) => col.name === name);
          const customProjectCol = getProjectCustomField(project, name);
          if (!ganttCol && !customProjectCol) return next;
          // add new column to config
          next.push({
            name: ganttCol?.name ?? name,
            defaultHide: ganttCol?.hide ?? false,
            hideByUser: hideByUser,
            touched: true,
            isCustom: !!customProjectCol,
          } as GanttColumnConfig);
        } else {
          column.hideByUser = hideByUser;
          column.touched = true;
        }
        storeColumnsConfig(next);
        return next;
      });
    },
    [project, gantt],
  );

  const getProjectCustomColumnsConfig = (): GanttColumnConfig[] => {
    return (
      project.customFieldDef?.map(
        ({internalFieldName}) =>
          ({
            name: internalFieldName,
            hideByUser: false,
            defaultHide: true,
            touched: false,
            isCustom: true,
          } as GanttColumnConfig),
      ) ?? []
    );
  };

  function getFallbackColumnsConfig(): GanttColumnConfig[] {
    const workerId = localStorage.getItem('workerId');
    const map = safeParseFromLocalStorage(GANTT_COLUMNS_SETTINGS);
    let columnsConfig = map?.[workerId]?.[projectId]?.[gantt.name] ?? [];
    if (!columnsConfig.length) {
      const legacySettings = safeParseFromLocalStorage(SELECTED_GANTT_COLUMNS);
      const visibleColsIds = legacySettings?.[workerId]?.[gantt.name] ?? [];
      columnsConfig = gantt.config.columns
        .filter((col) => !isNonHidingColumn(col.name))
        .map(
          ({name, hide}) =>
            ({
              name,
              defaultHide: hide,
              hideByUser: visibleColsIds.length ? !visibleColsIds.includes(name) : false,
              touched: visibleColsIds.includes(name),
              isCustom: false,
            } as GanttColumnConfig),
        );
    }
    return columnsConfig.concat(
      getProjectCustomColumnsConfig().filter(
        (col) => !columnsConfig.some(({name, isCustom}) => name === col.name && isCustom),
      ),
    );
  }

  const getDiffDefaultGanttColumns = (): {name: string; hide: boolean}[] => {
    const storedGanttCols = getStoredSettings().filter((col) => !col?.isCustom);
    const ganttDefaultCols = gantt.config.columns.filter(
      ({name}) =>
        !isNonHidingColumn(name) && !project.customFieldDef?.find(({internalFieldName}) => internalFieldName === name),
    );
    if (ganttDefaultCols.length !== storedGanttCols.length) {
      return ganttDefaultCols.filter(({name}) => !storedGanttCols.find((col) => col.name === name));
    }
    return [];
  };

  const getDiffCustomColumns = (): [GanttColumnConfig[]?, ProjectCustomFieldDef[]?] => {
    const storedSettings = getStoredSettings();
    const projectCustomCols = project.customFieldDef ?? [];
    if (!projectCustomCols.length) {
      return [];
    }
    // find custom columns removed from project
    const columnsToRemove = storedSettings.filter(
      (col) => col?.isCustom && !projectCustomCols.some(({internalFieldName}) => col.name === internalFieldName),
    );
    // find new custom columns
    const columnsToAdd = projectCustomCols.filter(
      ({internalFieldName}) => !storedSettings.some(({name, isCustom}) => isCustom && name === internalFieldName),
    );
    return [columnsToRemove, columnsToAdd];
  };

  useEffect(() => {
    const readyId = gantt.attachEvent(
      'onGanttReady',
      () => {
        if (gantt.config.columns.length) {
          setColumns(populateDropdownItems());
          const storedSettings = getStoredSettings();
          const initSettings = storedSettings ?? getFallbackColumnsConfig();
          if (!storedSettings) {
            storeColumnsConfig(initSettings);
          } else {
            const freshColsFromGanttConfig = [];
            const defaultFreshGanttCols = getDiffDefaultGanttColumns();
            if (defaultFreshGanttCols.length) {
              defaultFreshGanttCols.forEach(({name, hide}) =>
                freshColsFromGanttConfig.push({
                  name: name as GANTT_COLUMNS_NAMES,
                  hideByUser: false,
                  touched: false,
                  defaultHide: hide,
                  isCustom: false,
                }),
              );
            }
            const [customColumnsToRemove, customColumnsToAdd] = getDiffCustomColumns();
            setColumnsConfig(() => {
              const next = storedSettings
                .filter((col) => {
                  return col.isCustom ? !customColumnsToRemove?.some(({name}) => name === col.name) : true;
                })
                .concat(
                  customColumnsToAdd?.map(
                    ({internalFieldName}) =>
                      ({
                        name: internalFieldName,
                        hideByUser: false,
                        defaultHide: true,
                        touched: false,
                        isCustom: true,
                      } as GanttColumnConfig),
                  ) ?? [],
                )
                .concat(freshColsFromGanttConfig);
              storeColumnsConfig(next);
              return next;
            });
          }
          if (queryParams.ganttCols.length) {
            showPreferredColumns(initSettings, queryParams.ganttCols);
          }
        }
        renderColumns();
      },
      undefined,
    );
    return () => {
      gantt.detachEvent(readyId);
    };
  }, [gantt, projectId]);

  useEffect(() => {
    renderColumns();
  }, [columnsConfig]);

  const hiddenColumnsList = useMemo(() => {
    if (columnsConfig.length) {
      return columnsConfig
        .filter(({touched, defaultHide, hideByUser}) => (touched ? hideByUser : defaultHide))
        .map(({name}) => name);
    }
  }, [columnsConfig]);

  const getColumnOrder = useCallback(
    (columnName: string): number | null => {
      const ganttLayoutSettings = getLayoutSettings();
      if (ganttLayoutSettings?.columnOrder && Object.keys(ganttLayoutSettings.columnOrder ?? {}).length) {
        const columnOrder = ganttLayoutSettings.columnOrder[columnName];
        if (typeof columnOrder === 'number') {
          return columnOrder;
        }
      }
      return null;
    },
    [getLayoutSettings],
  );

  const populateDropdownItems = useCallback(() => {
    const projectCustomCols =
      project.customFieldDef?.map(({internalFieldName, fieldName}) => ({
        name: internalFieldName,
        label: fieldName,
        isCustom: true,
      })) ?? [];
    const projectCustomColNames = projectCustomCols.map(({name}) => name);

    return projectCustomCols.concat(
      gantt.config.columns
        .filter(({name}) => !isNonHidingColumn(name) && !projectCustomColNames.includes(name))
        .map(({name}) => ({name: name, isCustom: false, label: undefined}))
        .sort((a, b) => setColumnOrder(a.name) - setColumnOrder(b.name)),
    );
  }, [gantt.config.columns, project.customFieldDef]);

  const onGanttRender = useMemo(() => {
    if (gantt.config.columns.length !== columns.length) {
      const newColumns = populateDropdownItems();
      setColumns(newColumns);
      const storedSettings = getStoredSettings();
      const initSettings = storedSettings ?? getFallbackColumnsConfig();
      setColumnsConfig(initSettings);
    }
  }, [gantt.config.columns.length, columns.length, populateDropdownItems]);

  useEffect(() => {
    const id = gantt.attachEvent('onGanttRender', onGanttRender, undefined);
    return () => {
      gantt.detachEvent(id);
    };
  }, [gantt, onGanttRender]);

  function renderColumns() {
    if (!columnsConfig.length) return;
    const ganttCols = gantt.config.columns;
    columnsConfig.forEach((column) => {
      const {touched, hideByUser, defaultHide, name} = column;
      const projectCustomField = getProjectCustomField(project, name);
      const ganttCol = ganttCols.find((col) => col.name === name);
      const colOrder = getColumnOrder(name);
      if (ganttCol) {
        const isHidden = touched ? hideByUser : defaultHide;
        ganttCol.hide = isHidden && !isNonHidingColumn(column.name);
        if (projectCustomField && ganttCol.label !== projectCustomField.fieldName) {
          ganttCol.label = projectCustomField.fieldName;
        }
        if (colOrder !== null) {
          ganttCol.order = colOrder;
        }
      } else if (column.isCustom && projectCustomField) {
        const {internalFieldName, fieldName, fieldType} = projectCustomField;
        // insert column before tree dot column
        gantt.config.columns.splice(
          ganttCols.length - 1,
          0,
          generateColumn({
            name: name as GANTT_COLUMNS_NAMES,
            hide: touched ? hideByUser : defaultHide,
            label: fieldName,
            order: colOrder ?? ganttCols.length,
            width: getCustomColumnWidthByFieldType(fieldType),
            editor: getInlineEditorTypeForCustomColumn(fieldType),
            template: (task: GanttTask) => getCustomColumnCellTemplate(project, task, internalFieldName),
          }),
        );
        gantt.config.columns.sort((a, b) => a.order - b.order);
      }
    });
    gantt.dRender();
  }

  function showPreferredColumns(activeConfig: GanttColumnConfig[], preferredColsIds: string[]) {
    const preparedColsConfig: GanttColumnConfig[] = activeConfig.map(
      (col) =>
        ({
          ...col,
          name: col.name,
          defaultHide: col.defaultHide,
          hideByUser: !preferredColsIds.includes(col.name),
          touched: true,
        } as GanttColumnConfig),
    );

    if (preparedColsConfig.length) {
      setColumnsConfig(preparedColsConfig);
    }
  }

  function removeCustomColumn(colName: string) {
    const predicate = ({name}: {name: string}) => name !== colName;
    setColumnsConfig((prev) => {
      const next = prev.filter(predicate);
      storeColumnsConfig(next);
      return next;
    });
    setColumns((prev) => prev.filter(predicate));
    gantt.config.columns = gantt.config.columns.filter(predicate);
    gantt.dRender();
  }

  return {
    addNewCustomColumn,
    columns,
    hiddenColumnsList,
    removeCustomColumn,
    setColumnsConfig,
    updateColumnConfig,
  };
}
