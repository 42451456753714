import {decamelize} from 'humps';

import {TaskDetailsModelDTO} from 'shared/models/task/task';

export enum TaskStates {
  active = 'active',
  archived = 'archived',
  deleted = 'deleted',
}

export enum GroupMemberRole {
  assignee = 'assignee',
  owner = 'owner',
  member = 'member',
  responsible = 'responsible',
}

export enum TaskProjection {
  task = 'task',
  taskDetail = 'task_detail',
  taskMinimal = 'task_minimal',
  taskGantt = 'task_gantt',
}

export enum TaskActiveTab {
  info,
  assigners,
  chat,
}

export enum IssueActiveTab {
  info,
  chat,
}

export enum TaskObjectType {
  task = 'task',
  action = 'action',
  activity = 'activity',
  summary = 'summary',
  milestone = 'milestone',
}

export const SortField = {
  outlineSortKey: 'outline_sort_key',
} as const;

export enum TaskObjectSubType {
  start = 'start',
  end = 'end',
}

export const TASK_DATE_PROPERTIES = ['endDate', 'startDate', 'actualEnd', 'actualStart'];

export const getTaskEditableFields = (): string[] => {
  const camelizedKeys = [
    'duration',
    'startDate',
    'endDate',
    'actualStart',
    'actualEnd',
    'description',
    'location',
    'type',
    'status',
    'completionTarget',
    'completionUnit',
    'completionAmount',
    'responsibleOrgId',
    'objectType',
    'projectId',
    'name',
    'abbrev',
    'lookaheadColor',
    'csiCode',
    'customCode',
    'costCode',
    'phaseCode',
    'projectedLabor',
    'actualLabor',
    'calendarDays',
    'customFields',
    'estLaborHours',
  ] as (keyof TaskDetailsModelDTO)[];
  return [...camelizedKeys, ...camelizedKeys.map((key) => decamelize(key))];
};
