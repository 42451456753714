import classnames from 'classnames';
import {decamelize} from 'humps';
import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';
import {FixedSizeList as List} from 'react-window';

import CompanyApi from 'api/company';
import Button from 'shared/components/Button';
import {useConfirm} from 'shared/components/Confirmation';
import Icon from 'shared/components/Icon';
import Popup from 'shared/components/Popup';
import ProgressBar from 'shared/components/ProgressBar';
import LegendCompare from 'shared/components/TasksImport/components/LegendCompare';
import Tooltip from 'shared/components/Tooltip';
import {mixpanelEvents} from 'shared/constants/mixpanelEvents';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';

import {useTasksImportContext} from '../TasksImportContext/TasksImportContext';
import {statusSuffix, stylesMapping} from '../utils/constants';
import {TaskImportField, TaskImportFieldPreview, TaskImportResult} from '../utils/types';

import s from './styles.module.scss';

type TasksImportPreviewProps = {
  onSubmit: () => Promise<void>;
  result: TaskImportResult;
  fields: TaskImportField[];
  onBack: () => void;
  onClose: () => void;
  importPercentage: number;
};

const TasksImportPreview: FC<TasksImportPreviewProps> = ({
  onBack,
  onSubmit,
  onClose,
  result,
  fields,
  importPercentage,
}) => {
  const {confirm} = useConfirm();
  const {t} = useTranslation('import');
  const {projectId} = useParams<{projectId: string}>();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId}});
  const [{config}] = useTasksImportContext();
  const company = useCompany();
  const [importInProcess, setImportInProcess] = useState(false);
  const [subcontractorOptions, setSubcontractorOptions] = useState({});
  const [showOnlyErrors, setShowOnlyErrors] = useState(false);

  const handleSubmit = async () => {
    if (result.errors?.find((error) => error.code === 'missing_outline_parent')) {
      if (
        await confirm({
          description: t(
            'import_preview.description',
            'Some activities selected for import do not have summary activities. This may impact the Outline view in C4. Would you like to proceed or cancel and modify your file and/or import settings to include the Summary Tasks?',
          ),
          cancelButton: t('import_preview.buttons.cancel', 'Cancel'),
          acceptButton: t('import_preview.buttons.continue', 'Continue'),
        })
      ) {
        setImportInProcess(true);
        await onSubmit();
        setImportInProcess(false);
      } else {
        return onClose();
      }
    } else {
      setImportInProcess(true);
      await onSubmit();
      setImportInProcess(false);
    }
  };

  const whichMixPanelEvent = showOnlyErrors
    ? mixpanelEvents.tasks.import.showAllRows
    : mixpanelEvents.tasks.import.showMoreErrors;

  const toggleView = () => {
    mixpanel.trackWithAction(() => setShowOnlyErrors(!showOnlyErrors), whichMixPanelEvent);
  };

  useQuery(
    ['companySubcontractorsImport', company?.id],
    () => {
      return CompanyApi.getCompanyOrgs(company.id).then((res) => {
        const preparedOptions = res.reduce((acc, sub) => {
          acc[sub.id] = sub.group.name;
          return acc;
        }, {});
        setSubcontractorOptions(preparedOptions);
      });
    },
    {enabled: !!company?.id, refetchOnWindowFocus: false},
  );

  const taskResponses = showOnlyErrors
    ? result.taskResponses.filter((task) => !!task?.errors.length)
    : result.taskResponses;

  const TaskRow = ({index, style}) => {
    if (index === 0) {
      return (
        <div className="aka-table__tr" style={style}>
          {fields.map((col) => (
            <div
              key={col.key}
              className={`aka-table__cell aka-table__cell--th aka-table__cell--${stylesMapping[col.key]}`}
            >
              <span className="aka-table__value">{col.columnTitle}</span>
            </div>
          ))}
        </div>
      );
    }
    const taskCompare = taskResponses[index - 1];

    return (
      <div
        key={taskCompare.id}
        className={`aka-table__tr aka-table__tr--${statusSuffix[taskCompare?.status]}`}
        style={style}
      >
        {(fields as TaskImportFieldPreview[]).map((field: TaskImportFieldPreview) => {
          const isEdit = taskCompare.updatedFields.includes(decamelize(field.key));
          const isError = taskCompare.errors.find((error) => error.field === decamelize(field.key));
          const cellClassName = isError ? 'error' : isEdit ? 'edit' : '';
          let value = field.key === 'assignedNames' ? taskCompare.nameResolutions : taskCompare.task[field.key];
          if (field.key === 'subcontractor') {
            const responsibleOrgId = taskCompare.task['responsibleOrgId'];
            value = subcontractorOptions[responsibleOrgId];
          }

          return (
            <div
              key={field.key}
              className={`aka-table__cell aka-table__cell--td aka-table__cell--${stylesMapping[field.key]}`}
            >
              <span
                className={classnames(
                  'aka-table__value',
                  'aka-table__value--one-line',
                  cellClassName ? 'aka-table__value--' + cellClassName : '',
                  {
                    'aka-table__value--id': field.key === 'uniqueId',
                  },
                )}
              >
                {field.accessor
                  ? field.key === 'assignedNames'
                    ? field.accessor(value, taskCompare.nameResolutions)
                    : field.accessor(value, null, config)
                  : value}
                {(isEdit || isError) && (
                  <Tooltip
                    text={
                      isEdit
                        ? t('import_preview.tooltip.text', 'Content already exist and will be updated with new data')
                        : isError.messages.join(', ')
                    }
                    type={isEdit ? 'edit' : 'error'}
                    placement="top"
                  >
                    <Button
                      className="aka-table__value-button"
                      iconOnly
                      icon={<Icon name={isEdit ? 'swap_horizontal' : isError && 'error'} colorFill />}
                    >
                      {t('import_preview.buttons.show', 'Show info')}
                    </Button>
                  </Tooltip>
                )}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <Popup.Body>
        <div className="aka-table aka-table--import">
          <List height={500} itemCount={taskResponses.length + 1} itemSize={49}>
            {TaskRow}
          </List>
        </div>
      </Popup.Body>
      <Popup.Footer>
        <Button
          className="ctrl-btn--color-light ctrl-btn--shadow popup__button popup__button--back"
          icon={<Icon colorFill name="arrow_backward" />}
          onClick={() => onBack()}
        >
          {t('import_preview.buttons.back', 'Back to Mapping')}
        </Button>
        {importInProcess ? (
          <div className={s.progressbar__container}>
            <ProgressBar progress={importPercentage} />
          </div>
        ) : (
          <LegendCompare
            className="popup__legend"
            showErrors={showOnlyErrors}
            toggleErrors={toggleView}
            totals={{
              unChanged: result.unchangedCount,
              new: result.addedCount,
              edited: result.updatedCount,
              error: result.taskResponses.filter((taskResponse) => taskResponse.errors.length).length,
            }}
          />
        )}
        <Button
          data-cy="btnImportFinish"
          icon={importInProcess ? <Icon colorFill className="ctrl-btn__icon" name="autorenew" /> : null}
          className={`popup__button ${importInProcess ? 'is-processing' : 'ctrl-btn--color-success'}`}
          type="button"
          onClick={() => handleSubmit()}
          disabled={importInProcess}
        >
          {importInProcess
            ? t('import_preview.buttons.importing', 'Importing')
            : t('import_preview.buttons.finish', 'Finish')}
        </Button>
      </Popup.Footer>
    </>
  );
};
export default TasksImportPreview;
