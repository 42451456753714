import {GanttStatic} from 'dhtmlx-gantt';
import {FC, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import StateManager from 'react-select';

import {useInlineSelectController} from 'modules/Tasks/components/Gantt/components/Editors/useInlineSelectController';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {AsyncProjectSubcontractorSelect} from 'shared/components/CoreForm/Select/AsyncProjectSubcontractorSelect';
import {useMount} from 'shared/hooks/core/useMount';
import {CompanyOrgs} from 'shared/models/company';

type Props = {
  gantt: GanttStatic;
  task: GanttTask;
  name: string;
  disabled: boolean;
};
0;
const SubcontractorEditor: FC<Props> = ({gantt, task, name, disabled = false}: Props) => {
  const selectRef = useRef<StateManager>(null);
  const {t} = useTranslation('gantt');
  const {updateValue, onKeyUp, onKeyDown, onClose} = useInlineSelectController(gantt);

  useMount(() => {
    selectRef.current?.focus();
  });

  const updateSubcontractor = (value: string) => {
    const oldValue = task[name];
    if (oldValue !== value) {
      updateValue(task, name, value);
    }
  };

  const onAfterCreateSubcontractor = async (createdSubcontractor: CompanyOrgs) => {
    updateSubcontractor(createdSubcontractor.id);
  };

  return (
    <AsyncProjectSubcontractorSelect
      isCreatable
      ref={selectRef}
      size="xs"
      value={task[name]}
      placeholder={t('subcontractor.placeholder', 'Company')}
      onChange={updateSubcontractor}
      loadingPlaceholder={t('subcontractor.loading_placeholder', 'Wait...')}
      _projectId={task.project_id || task.projectId}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMenuClose={onClose}
      menuIsOpen={true}
      closeMenuOnSelect={false}
      tabSelectsValue={false}
      onAfterCreateOption={onAfterCreateSubcontractor}
      isDisabled={disabled}
    />
  );
};

export default SubcontractorEditor;
