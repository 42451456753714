import {Reducer} from 'react';

import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import {createActionCreatorWrapper, createActions, InferActionsReturnType} from 'shared/helpers/reducerHelpers';

import {TaskImportStep, TasksImportSourceType} from '../utils/constants';
import {TaskImportPrepResponse, TaskImportResult, TasksImportConfig} from '../utils/types';

export interface TasksImportState {
  sourceType: TasksImportSourceType;
  currentStep: TaskImportStep;
  parseResult: TaskImportPrepResponse;
  file: File;
  importErrorCode: string;
  csv: string;
  config: TasksImportConfig;
  showResult: boolean;
  compareResult: TaskImportResult;
  completePercentage: number;
  asyncUploadId: string;
  procoreProjects: CoreOptionType[];
}

export function getDefaultTaskImportContext(): TasksImportState {
  return {
    sourceType: null,
    currentStep: TaskImportStep.SelectSource,
    parseResult: null,
    file: null,
    importErrorCode: null,
    csv: null,
    config: null,
    compareResult: null,
    showResult: false,
    completePercentage: 0,
    asyncUploadId: null,
    procoreProjects: null,
  };
}

export const actionCreators = createActions({
  setCurrentStep: createActionCreatorWrapper<number>(),
  setParseResult: createActionCreatorWrapper<TaskImportPrepResponse>(),
  setFileSource: createActionCreatorWrapper<File>(),
  setErrorCode: createActionCreatorWrapper<string>(),
  setScv: createActionCreatorWrapper<string>(),
  setConfig: createActionCreatorWrapper<TasksImportConfig>(),
  setCompareResult: createActionCreatorWrapper<TaskImportResult>(),
  setShowResult: createActionCreatorWrapper<boolean>(),
  setPercentage: createActionCreatorWrapper<number>(),
  asyncUploadId: createActionCreatorWrapper<string>(),
  setProcoreProjects: createActionCreatorWrapper<CoreOptionType[]>(),
  setProcoreSource: createActionCreatorWrapper<void>(),
  setSourceType: createActionCreatorWrapper<TasksImportSourceType>(),
});

type ImportActions = InferActionsReturnType<typeof actionCreators>;

export const taskImportReducer: Reducer<TasksImportState, ImportActions> = (state, action) => {
  switch (action.type) {
    case 'setCurrentStep':
      return {...state, currentStep: action.payload};
    case 'setParseResult':
      return {...state, parseResult: action.payload};
    case 'setErrorCode':
      return {...state, importErrorCode: action.payload};
    case 'setScv':
      return {...state, csv: action.payload};
    case 'setConfig':
      return {...state, config: action.payload};
    case 'setCompareResult':
      return {...state, compareResult: action.payload};
    case 'setShowResult':
      return {...state, showResult: action.payload};
    case 'setPercentage':
      return {...state, completePercentage: action.payload};
    case 'asyncUploadId':
      return {...state, asyncUploadId: action.payload};
    case 'setProcoreProjects':
      return {...state, procoreProjects: action.payload};
    case 'setProcoreSource': {
      return {
        ...state,
        file: null,
        sourceType: TasksImportSourceType.proCore,
      };
    }
    case 'setFileSource':
      return {
        ...state,
        file: action.payload,
        sourceType: action.payload ? TasksImportSourceType.file : TasksImportSourceType.proCore,
      };
    case 'setSourceType':
      return {
        ...state,
        sourceType: action.payload,
      };
    default:
      return state;
  }
};
