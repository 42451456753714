import {isSame, toShortIso} from 'shared/helpers/dates';
import {ProjectModel} from 'shared/models/project';

import {FormValues} from './types';
import {TaskObjectType} from 'shared/models/task/const';
import {TaskDetailsModelDTO} from 'shared/models/task/task';

interface PrepareModelBeforeSaveParams {
  values: FormValues;
  task: TaskDetailsModelDTO;
  project: ProjectModel;
  parentId: string;
}

export function prepareModelBeforeSave({values, task, parentId}: PrepareModelBeforeSaveParams) {
  let completionTarget = '';
  if (typeof values.completionTarget === 'number' || typeof values.completionTarget === 'string') {
    completionTarget = values.completionTarget.toString();
  }
  let completionAmount = '';
  if (typeof values.completionAmount === 'number' || typeof values.completionAmount === 'string') {
    completionAmount = values.completionAmount.toString();
  }
  const model = {
    ...task,
    ...values,
    type: values.type || null,
    objectType: values?.objectType || TaskObjectType.activity,
    status: values.status,
    completionTarget: completionTarget,
    completionAmount: completionAmount,
    startDate: values.startDate && toShortIso(values.startDate),
    endDate: values.endDate && toShortIso(values.endDate),
    actualStart: values.actualStart && toShortIso(values.actualStart),
    actualEnd: values.actualEnd && toShortIso(values.actualEnd),
    duration: values.duration && Number(values.duration),
    estLaborHours: values.estLaborHours ? Number(values.estLaborHours) : null,
  };
  // add parent attributes for Subtask
  if (parentId) {
    model.parentId = parentId;
  }

  // changed duration, start + duration
  if (!values.endDate && values.duration) {
    delete model.endDate;
  }
  // changed end, start + end
  if (values.endDate && !values.duration) {
    delete model.duration;
  }
  // change start, start + duration
  if (task?.startDate && values.duration && !isSame(task.startDate, values.startDate)) {
    delete model.endDate;
  }
  return model;
}
