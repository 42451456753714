export type TaskChatHistoryStatusResponse = {
  progressPercent?: number;
  result?: {
    events: TaskChatEvent[];
  };
  status?: AsyncJobChatHistoryStatus;
};

type TaskChatEventTypes =
  | 'm.room.member' // member list is changed
  | 'm.room.message.text'
  | 'm.room.message.image'
  | 'm.room.message' // raw system message
  | 'm.room.name' // changed room name
  | 'm.room.avatar' // changed room avatar
  | 'm.room.redaction' // messaged is edited
  // changed task status
  | 'com.bycore.task.rework'
  | 'com.bycore.task.in_progress'
  | 'com.bycore.task.approved'
  | 'com.bycore.task.blocked'
  | 'com.bycore.task.archived'
  | 'com.bycore.task.closed'
  | 'com.bycore.task.assigned'
  | 'com.bycore.task.tba'
  | 'com.bycore.task.done'
  | 'com.bycore.task.verified'
  | 'com.bycore.task.assigned.worker'
  | 'com.bycore.task.assigned.admihn';

export interface TaskChatEvent extends TaskChatEventContent {
  content?: TaskChatEventContent;
  eventId?: string;
  membership?: string;
  prevContent?: TaskChatEventContent;
  prevMembership?: string;
  sender?: string;
  serverTimestamp?: number;
  stateKey: string;
  type?: TaskChatEventTypes;
}

type TaskChatAttachment = {
  url: string;
  info: {h: number; w: number};
};

type TaskChatEventContent = {
  attachments?: TaskChatAttachment[];
  body?: string;
  formattedBody?: string;
  avatarUrl?: string;
  displayname?: string;
  membership?: string;
  'm.relatesTo'?: {
    'm.inReplyTo'?: {eventId: string};
    eventId?: string;
    relType?: string;
  };
  'm.newContent'?: {
    body?: string;
    msgtype?: string;
  };
  msgtype?: string;
  url?: string;
  reason?: string;
  redactedBecause: TaskChatRedactedBecause;
};

type TaskChatRedactedBecause = {
  age?: number;
  content?: TaskChatEventContent;
  eventId?: string;
  originServerTs?: number;
  redacts?: string;
  roomId?: string;
  sender?: string;
  type?: string;
  unsigned?: {
    age?: number;
  };
  userId?: string;
};

export enum AsyncJobChatHistoryStatus {
  failed = 'failed',
  running = 'running',
  finished = 'finished',
  queued = 'queued',
}

export type MatrixConnectionToken = {
  id: string;
  password: string;
};

export type MatrixSessionBody = {
  type: 'm.login.password';
  identifier: {
    type: 'm.id.user';
    user: string;
  };
  password: string;
};

export type MatrixSessionResponse = {
  userId: string;
  accessToken: string;
  homeServer: string;
  deviceId: string;
  wellKnown: {
    ['m.homeserver']: {
      baseUrl: string;
    };
  };
};
