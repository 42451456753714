import {InitExportResponse, TaskExportPayload, TaskExportResponse} from 'shared/components/TasksExport/types';
import {prepareTaskFilters} from 'shared/helpers/tasks';

import ApiAxios from './axios';

class ExportService {
  private getBasePath(companyId: string) {
    return `/companies/${companyId}/tasks/export`;
  }

  sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  exportTasks(companyId: string, payload: Partial<TaskExportPayload>) {
    return ApiAxios.post<InitExportResponse>(this.getBasePath(companyId), null, {
      params: {
        filter_params: JSON.stringify(prepareTaskFilters(payload.params)),
        dateformat: payload.dateFormat,
      },
    });
  }

  exportTasksStatus(companyId: string, importId: string) {
    return ApiAxios.get<TaskExportResponse>(`${this.getBasePath(companyId)}/${importId}/status`);
  }

  async pollExportTasksResults(companyId: string, importId: string, maxWaitSec: number) {
    const resp = await this.exportTasksStatus(companyId, importId);
    let data = resp.data;
    let iters = 1;
    while (data && (data.status === 'queued' || data.status === 'running') && iters < maxWaitSec) {
      await this.sleep(2000);
      const response = await this.exportTasksStatus(companyId, importId);
      data = response.data;
      iters += 1;
    }

    return data;
  }
}

export default new ExportService();
