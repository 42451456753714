import {safeParseDate} from 'shared/helpers/dates';
import {TaskDetailsModelDTO} from 'shared/models/task/task';

export const getPreparedFormikValues = (values: Partial<TaskDetailsModelDTO>) => {
  return {
    actualEnd: safeParseDate(values.actualEnd),
    actualStart: safeParseDate(values.actualStart),
    averageLabor: values.feedbackRollup.averageLabor,
    calendarDays: values.calendarDays,
    completionAmount: values.completionAmount,
    completionTarget: values.completionTarget,
    completionUnit: values.completionUnit || '%',
    costCode: values.costCode,
    csiCode: values.csiCode,
    customCode: values.customCode,
    customFields: values.customFields,
    description: values.description,
    duration: values.duration,
    endDate: safeParseDate(values.endDate),
    estLaborHours: values.estLaborHours,
    location: values.location,
    name: values.name,
    objectType: values.objectType,
    phaseCode: values.phaseCode,
    projectedLabor: values.projectedLabor,
    projectId: values.projectId,
    responsibleOrgId: values.responsibleOrgId,
    startDate: safeParseDate(values.startDate),
    status: values.status,
    type: values.type,
  };
};
