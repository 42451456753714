import axios from 'axios';
import {camelizeKeys} from 'humps';

import TasksApi from 'api/tasks';
import {AppConstantsResponse} from 'shared/hooks/useAppConstants';

import {camelCaseConverter} from './axios';

export async function createMatrixAxiosInstance() {
  const appConstants: AppConstantsResponse = await TasksApi.getAppConstants();
  if (appConstants) {
    const instance = axios.create({
      baseURL: appConstants.locales.uS.matrix.homeserver,
    });
    instance.interceptors.response.use((res) => {
      if (res.config.disableCamelCaseConverter) {
        return res;
      }
      if (res.data && res.headers['content-type'] === 'application/json') {
        res.data = camelizeKeys(res.data, camelCaseConverter);
      }
      return res;
    });
    return instance;
  } else {
    throw new Error('Failed to fetch configuration');
  }
}
