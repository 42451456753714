export const defaultReactSelectStyles = {
  clearIndicator: () => ({cursor: 'pointer'}),
  container: () => ({}),
  control: () => ({}),
  dropdownIndicator: () => ({cursor: 'pointer'}),
  group: () => ({}),
  groupHeading: () => ({}),
  indicatorsContainer: () => ({}),
  indicatorSeparator: () => ({}),
  input: () => ({}),
  loadingIndicator: () => ({}),
  loadingMessage: () => ({}),
  menu: ({bottom, ...rest}) => ({bottom, top: 'unset !important', rest}), // need to fix
  menuList: () => ({}),
  menuPortal: (base) => ({...base, zIndex: 9999}),
  multiValue: () => ({}),
  multiValueLabel: () => ({}),
  multiValueRemove: () => ({}),
  noOptionsMessage: () => ({}),
  option: () => ({}),
  placeholder: () => ({}),
  singleValue: () => ({}),
  valueContainer: () => ({}),
};
